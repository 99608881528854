<template>
    <div class="partner z-0">
      <block-title :en="partner.en" :title="partner.value" :sub="partner.sub"></block-title>
      <div class="p-container">
        <div class="list content-limit mt-66px!">
          <div class="item pi1" v-for="(item, index) of partner1" :key="index">
            <img :src="item" alt="" />
          </div>
        </div>
        <div class="list content-limit">
          <div class="item pi2" v-for="(item, index) of partner2" :key="index">
            <img :src="item" alt="" />
          </div>
        </div>
        <div class="list content-limit">
          <div class="item pi1" v-for="(item, index) of partner3" :key="index">
            <img class="img" :src="item" alt="" />
          </div>
        </div>
        <div class="list content-limit">
          <div class="item pi2" v-for="(item, index) of partner4" :key="index">
            <img class="img" :src="item" alt="" />
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import anime from 'animejs/lib/anime.es.js';
  import partic1 from './asset/index_1.png';
  import partic2 from './asset/index_2.png';
  import partic3 from './asset/index_3.png';
  import partic4 from './asset/index_4.png';
  import partic5 from './asset/index_5.png';
  import partic6 from './asset/index_6.png';
  import partic7 from './asset/index_7.png';
  import partic8 from './asset/index_8.png';
  import partic9 from './asset/index_9.png';
  import partic10 from './asset/index_10.png';
  import partic11 from './asset/index_11.png';
  import partic12 from './asset/index_12.png';
  import partic13 from './asset/index_13.png';
  import partic14 from './asset/index_14.png';
  import partic15 from './asset/index_15.png';
  import partic16 from './asset/index_16.png';
  import partic17 from './asset/index_17.png';
  import partic18 from './asset/index_18.png';
  import partic19 from './asset/index_19.png';
  import partic20 from './asset/index_20.png';
  import partic21 from './asset/index_21.png';
  import partic22 from './asset/index_22.png';
  import partic23 from './asset/index_23.png';
  import partic24 from './asset/index_24.png';
  import partic25 from './asset/index_25.png';
  import partic26 from './asset/index_26.png';
  import partic27 from './asset/index_27.png';
  import partic28 from './asset/index_28.png';
  
  
  export default {
    data() {
      return {
        partner1: [partic1, partic2, partic3, partic4, partic5,partic21, partic22],
        partner2: [partic6, partic7, partic8, partic9, partic10,partic23, partic24],
        partner3: [partic11, partic12, partic13, partic14, partic15,partic25, partic26],
        partner4: [partic16, partic17, partic18, partic19, partic20,partic27, partic28]
      };
    },
    computed: {
      globalData() {
        return this.$store.getters.globalData;
      },
      partner() {
        return this.globalData.index.partner;
      }
    },
    mounted() {
      let xTrans = [];
      anime.set('.partner .pi1', {
        translateX: function (el, i, l) {
          xTrans[i] = { x: i * 228 };
          return i * 228;
        }
      });
  
      window.ani = anime({
        targets: xTrans,
        duration: 35000, //走一周持续时间
        easing: 'linear',
        x: '+=1596',
        loop: true,
        update: function (anim) {
          anime.set('.partner .pi1', {
            translateX: function (el, i, l) {
              return (xTrans[i].x % 1596) - 228;
            }
          });
        }
      });
      // 走马灯2;
      let xTranss = [];
      anime.set('.partner .pi2', {
        translateX: function (el, i, l) {
          xTranss[i] = { xx: i * 228 };
          return i * 228;
        }
      });
  
      anime({
        targets: xTranss,
        duration: 35000, //走一周持续时间
        easing: 'linear',
        xx: '+=1596',
        loop: true,
        update: function (anim) {
          anime.set('.partner .pi2', {
            translateX: function (el, i, l) {
              return (xTranss[i].xx % 1596) - 114 - 228;
            }
          });
        }
      });
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .partner {
    padding: 80px 0;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: calc(((100% - 1440px) / 2) + 300px);
      height: 100%;
      left: 0;
      top: 0;
      background: linear-gradient(to right, white, rgba(255, 255, 255, 0.2));
      z-index: 9;
    }
    &::after {
      content: '';
      position: absolute;
      width: calc(((100% - 1440px) / 2) + 300px);
      height: 100%;
      right: 0;
      top: 0;
      background: linear-gradient(to left, white, rgba(255, 255, 255, 0.2));
      z-index: 9;
    }
    .list {
      position: relative;
      margin-top: 30px;
      overflow: hidden;
      height: 146px;
      .item {
        position: absolute;
        width: 228px;
        height: 146px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .button {
      color: white;
      font-size: 24px;
      width: 217px;
      height: 61px;
      margin: 0 auto;
      margin-top: 66px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);
      .icon {
        width: 30px;
      }
    }
  
    .p-container {
      mask: radial-gradient(circle at center, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)); 
    }
  }
  </style>
  