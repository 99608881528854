<template>
  <div>
    <top-nav :imgList="banner" white hideButton isParent>
      <div class="bannerTxt" style="font-size: 88px;">{{ globalData.product.txt1 }}</div>
      <div class="head-txt">{{ globalData.product.txt2 }}</div>
    </top-nav>
    <div class="service content-limit">
      <block-title :title="globalData.product.service.top.title" :en="globalData.product.service.top.en"
        :sub="globalData.product.service.top.sub"></block-title>
      <div class="list">
        <div class="item" v-for="(item, index) of service" :key="index"  @click="showDetail(item)">
          <img class="img" :src="item.img" alt="" />
          <div class="hover">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <!-- <div class="theory">
      <block-title :title="globalData.product.theory.top.title" :en="globalData.product.theory.top.en"
        :sub="globalData.product.theory.top.sub"></block-title>
      <div class="step content-limit">
        <div class="item" v-for="(item, index) of step" :key="index">
          <div class="item1" v-if="item.type === 1">
            <div class="top">
              <img class="icon" :src="item.icon" alt="" />
            </div>
            <div class="middle">
              <div class="icon">{{ item.num }}</div>
            </div>
            <div class="bottom">{{ item.txt }}</div>
          </div>
          <div class="item2" v-if="item.type === 2">
            <div class="top">
              <img src="@/asset/product/ic_product_19.png" alt="" class="icon" />
            </div>
            <div class="middle"></div>
          </div>
        </div>
      </div>
      <div class="display">
        <div class="left">
          <div class="title">
            {{ globalData.product.theory.display.left.title }}
          </div>
          <div class="brief">
            {{ globalData.product.theory.display.left.brief }}
          </div>
        </div>
        <div class="right"></div>
      </div>
    </div> -->
    <!-- <div class="feature content-limit">
      <block-title :title="globalData.product.feature.top.title" :en="globalData.product.feature.top.en"
        :sub="globalData.product.feature.top.sub"></block-title>
      <div class="list mt-80px">
        <div class="item" v-for="(item, index) of feature" :key="index">
          <img class="icon" :src="item.icon" alt="" />
          <div class="title">{{ item.title }}</div>
          <pre class="brief">{{ item.brief }}</pre>
        </div>
      </div>
      <block-title :title="globalData.product.feature.middle.title" :en="globalData.product.feature.middle.en"
        :sub="globalData.product.feature.middle.sub"></block-title>
      <div class="end">
        <div class="item" v-for="(item, index) of end" :key="index">
          <div class="txt">
            <div class="title">{{ item.title }}</div>
            <div class="brief">{{ item.brief }}</div>
          </div>
          <img class="img" :src="item.img" alt="" />
        </div>
      </div>
    </div> -->
    <!-- <product-list :index.sync="pIndex" noTitle  :limited="false"></product-list> -->
    <div class="hover-pop-success" v-if="showing">
      <div class="private-back" @click="closeDetail"></div>
      <div
        class="private-content"
        :style="{
          background: `url('/static-images/prompt_bg.png') no-repeat center/cover`,
        }"
      >
        <div class="private-title">
          产品详情
          <img
            @click="closeDetail"
            class="private-close success-close"
            src="/static-images/ic_close.png"
          />
        </div>
        <div class="pd-name">{{ showingDetail.name }}</div>
        <div class="pd-content">
          <div class="pd-left">
            <img :src="showingDetail.img" class="pd-img" alt="" />
          </div>
          <div class="pd-right">
            <div class="pd-brief">{{ showingDetail.brief }}</div>
            <div class="pd-feature" v-html="showingDetail.feature"></div>
          </div>
        </div>
        <div class="pd-buttons" style="margin: 0 auto;">
          <div class="private-button" @click="closeDetail">确定</div>
        </div>
      </div>
    </div>
    <customer-wall></customer-wall>
    <my-footer></my-footer>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["globalData"]),
    service() {
      return window.deepMerge(
        [
          {
            img: require("@/asset/product/ic_product_02.png"),
            name: "III型集群表演无人机",
          },
          {
            img: require("@/asset/product/ic_product_03.png"),
            name: "敏捷蜂 II 型无人机",
          },
          {
            img: require("@/asset/product/ic_product_04.png"),
            name: "敏捷蜂 I 型无人机",
          },
          {
            img: require("@/asset/product/ic_product_05.png"),
            name: "多旋翼无人机-览雀S6",
          },
          {
            img: require("@/asset/product/ic_product_06.png"),
            name: "纵列式双旋翼承鹰E80",
          },
          {
            img: require("@/asset/product/ic_product_07.png"),
            name: "多旋翼无人机-览雀D6",
          },
          {
            img: require("@/asset/product/ic_product_08.png"),
            name: "多旋翼无人机-鸾凤",
          },
          {
            img: require("@/asset/product/ic_product_09.png"),
            name: "无人直升机MO300",
          },
          {
            img: require("@/asset/product/ic_product_10.png"),
            name: "纵列式双旋翼承鹰240",
          },
          {
            img: require("@/asset/product/ic_product_11.png"),
            name: "巡检自动机场-驿桐",
          },
         
        ],
        this.globalData.product.service.list
      );
    },
    step() {
      return window.deepMerge(
        [
          {
            icon: require("@/asset/product/ic_product_15.png"),
            type: 1,
            num: 1,
            txt: "专业传感器",
          },
          {
            type: 2,
          },
          {
            icon: require("@/asset/product/ic_product_16.png"),
            type: 1,
            num: 2,
            txt: "通讯网络",
          },
          {
            type: 2,
          },
          {
            icon: require("@/asset/product/ic_product_17.png"),
            type: 1,
            num: 3,
            txt: "监测预警平台",
          },
          {
            type: 2,
          },
          {
            icon: require("@/asset/product/ic_product_18.png"),
            type: 1,
            num: 4,
            txt: "发布告警信息",
          },
        ],
        this.globalData.product.theory.step
      );
    },
    feature() {
      return window.deepMerge(
        [
          {
            icon: require("@/asset/product/ic_product_22.png"),
            title: "设备集成度高",
            brief: "轻量化设计，易安\n装，使用便捷",
          },
          {
            icon: require("@/asset/product/ic_product_23.png"),
            title: "智能化成度高",
            brief: "全自动数据采集，处\n理，上报及分析",
          },
          {
            icon: require("@/asset/product/ic_product_24.png"),
            title: "高效率，精细化作业",
            brief: "附属设备一次性采\n集，高精度计算",
          },
          {
            icon: require("@/asset/product/ic_product_25.png"),
            title: "识别可用性高",
            brief: "精准识别，去重，成\n果结构化输出",
          },
        ],
        this.globalData.product.feature.list
      );
    },
    end() {
      return window.deepMerge(
        [
          {
            title: "物联万物",
            brief:
              "智能终端，作为现代科技与人类生活紧密结合的产物，已经深入到我们日常生活的每一个角落。它们不仅仅是我们与外界信息交互的工具，更是我们与世界连接的桥梁。这些智能终端，无论是手机、电脑还是各种智能设备，都在不断地收集、处理和分享着海量的数据。智能终端通过相互连接，将这些时空数据汇聚在一起。这种连接不仅仅是设备与设备之间的连接，更是数据与数据之间的连接。这种连接突破了传统的虚拟与现实的界限。在以前，虚拟世界和现实世界是相对独立的，但在智能终端的时代，这两者之间的界限正在变得越来越模糊。",
            img: require("@/asset/product/ic_product_26.png"),
          },
          {
            title: "设备自主决策",
            brief:
              "设备传感器在我们的日常生活中扮演着越来越重要的角色。它们能够感知周围环境的变化，收集各种信息，并进行计算和分析，为设备提供决策依据。在获取环境信息后，设备传感器会进行计算。这包括对数据的处理、分析和学习。例如，智能摄像头可以通过图识别技术，对拍摄到的画面进行自动分类和标记，从而为用户提供更加便捷的搜索和浏览体验。",
            img: require("@/asset/product/ic_product_27.png"),
          },
          {
            title: "推动城市发展",
            brief:
              "智能设备传感器的引入可以大大减少人工参与，从而节约人力成本。在许多传统行业中，如制造业、物流业等，人工参与往往占据了很大一部分成本。通过引入智能设备传感器，可以实现自动化和智能化操作，减少人工干预，从而降低人力成本。同时，传感器还可以实现数据的实时传输和处理，为生产决策提供更加准确和及时的信息，进一步提高生产效率。",
            img: require("@/asset/product/ic_product_28.png"),
          },
        ],
        this.globalData.product.feature.end
      );
    },
  },
  data() {
    return {
      showingDetail: {},
      showing:false,
      noTitle:false,
      pIndex: 0,
      banner: require("@/asset/product/ic_product_01.png"),
    };
  },
  methods:{
    closeDetail() {
      this.showing = false;
      document.body.style.overflow = "auto";
    },
    showDetail(item) {
      this.showingDetail = item;
      console.log(item,'xxx')
      this.showing = true;
      document.body.style.overflow = "hidden";
    },
    industryCurrentIndex(i){
      console.log(i,'xxx')
      this.pIndex=i
      this.noTitle=true
    }
  }
};
</script>
<style lang="scss" scoped>
.privateBox,.hover-pop-success {
  .private-back {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba($color: #000000, $alpha: 0.6);
    z-index: 9999;
  }
  .pd-name {
  font-size: 24px;
  color: #096dd9;
  margin-top: 80px;
  padding-left: 100px;
}
.pd-content {
  display: flex;
  align-items: center;
  gap: 80px;
  padding: 40px 24px 80px 100px;
  height: 410px;
  .pd-left {
    width: 410px;
    height: 410px;
    background: #ffffff;
    box-shadow: 5px 8px 20px 0px #dadada;
    border-radius: 8px 8px 8px 8px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .pd-img {
      width: 377px;
    }
  }
  .pd-right {
    height: 100%;
    flex-grow: 1;
    overflow-y: scroll;
    line-height: 2;
    font-size: 16px;
    padding-right: 70px;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
    }
    &::-webkit-scrollbar-track {
      background: #e7e7e7;
    }

    .pd-brief {
      color: #666666;
      margin-bottom: 8px;
    }
    .pd-feature {
      color: #096dd9;
      margin-bottom: 8px;
    }
  }
}
  
  .private-content {
    position: fixed;
    width: 1280px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    padding-bottom: 40px;
    .private-title {
      color: white;
      font-size: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
      position: relative;
      .private-close {
        position: absolute;
        right: 24px;
        top: 22px;
        width: 16px;
        cursor: pointer;
      }
    }
    .private-button {
      margin: 0 auto;
      width: 157px;
      height: 48px;
      background: linear-gradient(180deg, #4e99fd 0%, #3384e8 100%);
      border-radius: 2px 2px 2px 2px;
      font-size: 20px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}
.bannerTxt {
  font-family: YouSheBiaoTiHei;
  font-size: 60px;
  color: white;
  margin-bottom: 24px;
  text-align: center;
}

.head-txt {
  // font-family: Source Han Sans CN-Medium;
  font-weight: 400;
  font-size: 30px;
  color: #FFFFFF;
  text-align: center;
  margin-top: 40px;
}

.service {
  padding: 100px 0 80px;

  .list {
    width: 1280px;
    display: flex;
    flex-wrap: wrap;
    gap: 60px 24px;
    margin-top: 80px;

    .item {
      background: #ffffff;
      box-shadow: 3px 3px 20px 1px rgba(221, 221, 221, 0.4);
      border-radius: 8px 8px 8px 8px;
      width: 410px;
      height: 440px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .img {
        width: 80%;
      }

      .hover {
        position: absolute;
        left: 12px;
        right: 12px;
        bottom: 43px;
        transition: all 0.5s;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: #666666;
        height: 1em;
      }

      &:hover {
        .hover {
          background: rgba(78, 153, 253, 0.6);
          height: 175px;
          color: white;
        }
      }
    }
  }
}

.theory {
  padding: 80px 0 40px;
  background: url(@/asset/product/ic_product_14.png) no-repeat center/cover;

  .step {
    display: flex;
    align-items: flex-start;
    margin-top: 80px;

    .item {
      &:first-child {
        .middle {
          border-radius: 16px 0 0 16px;
        }
      }

      &:last-child {
        .middle {
          border-radius: 0 16px 16px 0;
        }
      }
    }

    .item1 {
      flex-grow: 1;

      .top {
        background: #4e99fd;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 36px;
        width: 210px;
        height: 127px;

        .icon {
          height: 60px;
        }
      }

      .middle {
        background: #deecf6;
        margin-bottom: 30px;
        width: 100%;
        height: 44px;
        position: relative;

        .icon {
          width: 56px;
          height: 56px;
          background: url(@/asset/product/ic_product_20.png) no-repeat center/contain;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          font-size: 24px;
          color: #4e99fd;
          text-align: center;
          line-height: 56px;
        }
      }

      .bottom {
        color: #666666;
        font-size: 32px;
        text-align: center;
      }
    }

    .item2 {
      flex-grow: 1;

      .top {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 36px;
        width: 146px;
        height: 127px;

        .icon {
          width: 97px;
        }
      }

      .middle {
        background: #deecf6;
        width: 100%;
        height: 44px;
      }
    }
  }
}

.display {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;

  .left {
    box-shadow: -10px 10px 0 #dddddd;
    width: 569px;
    height: 193px;
    padding: 64px;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    background: white;
    margin-right: -64px;
    position: relative;

    .title {
      width: 100%;
      font-size: 20px;
      margin-bottom: 20px;
      color: #4e99fd;
    }

    .brief {
      width: 100%;
      font-size: 20px;
      color: #666666;
    }
  }

  .right {
    width: 658px;
    height: 411px;
    background: url(@/asset/product/ic_product_21.webp) no-repeat center/cover;
    box-shadow: -10px 10px 0 #1e63c1;
    border-radius: 8px;
  }
}

.feature {
  padding: 80px 0;

  .list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 80px;

    .item {
      text-align: center;

      .icon {
        width: 65px;
        margin-bottom: 4px;
      }

      .title {
        font-size: 24px;
        color: #3384e8;
        margin-bottom: 13px;
      }

      .brief {
        font-size: 18px;
        color: #666666;
        line-height: 24px;
        margin: 0;
      }
    }
  }

  .end {
    margin-top: 40px;

    .item {
      display: flex;
      gap: 90px;
      margin-bottom: 80px;

      &:last-child {
        margin-bottom: 0;
      }

      &:nth-child(2n) {
        flex-direction: row-reverse;

        .title {
          text-align: right;
        }
      }

      .txt {
        flex: 1;

        .title {
          font-size: 24px;
          color: #0865aa;
          margin-bottom: 30px;
        }

        .brief {
          font-size: 18px;
          color: #999999;
          line-height: 2;
        }
      }

      .img {
        flex: 1;
      }
    }
  }
}
</style>
