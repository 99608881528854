<template>
	<div class="footer-container">
		<img class="relative-center" src="../../asset/index/ic_index_06.png" alt="">
		<div class="grid-box">
			<div class="left">

				<div class="qrcode-box-item">
					<div class="qrcode-box">
						<img class="qrcode" src="./assets/footer-qrcode2.png" style="width: 80px;height: 80px;">
						<div class="qr-title">微信公众号</div>
					</div>
					<div class="qrcode-box" style="margin-left: 45px;">
						<img class="qrcode" src="./assets/footer-qrcode1.png" style="width: 80px;height: 80px;">
						<div class="qr-title">微信咨询</div>
					</div>
				</div>

				<div class="company-info">
					<div class="company-item">
						<div class="company-head">
							<img src="./assets/ic_index_79.png" style="width: 32px;height: 32px;margin-right: 8px;" />
							<div>400-033-8188</div>
						</div>
						<div class="tips">服务热线</div>
					</div>
					<div class="company-item">
						<div class="company-head">
							<img src="./assets/ic_index_80.png" style="width: 32px;height: 32px;margin-right: 8px;" />
							<div style="font-size: 14px;">778016138@qq.com</div>
						</div>
						<div class="tips">电子邮箱</div>
					</div>
					<div class="company-item">
						<div class="company-head">
							<img src="./assets/ic_index_81.png" style="width: 32px;height: 32px;margin-right: 8px;" />
							<div style="font-size: 14px;line-height: 24px;">四川省成都市天府新区华阳街道天府大道南段888号附OL—07—202312102号
							</div>
						</div>
						<div class="tips">公司地址</div>
					</div>
				</div>
				<div style="position: relative;bottom: 4px;margin-left: 300px;">
					<div class="footer-input"><input placeholder="* 姓名" type="text" v-model="name" /></div>
					<div class="footer-input footer-submit"><input placeholder="* 联系电话" type="text" v-model="phone" />
						<div class="tj" @click="submit">提交</div>
					</div>
					<div class="footer-input"><input placeholder="公司名称" v-model="company" type="text" /></div>
				</div>

			</div>
			<!-- <div class="right">
				<div class="render-list" v-for="t in list" :key="t.name">
					<div class="top-title" @click="goTo(t.path)">{{ t.name }}</div>
					<div class="r-children">
						<div class="r-child" v-for="c in t.children" :key="c.name" @click="goTo(c.path)"
							:class="{ selected: isSelected(c) }">{{ c.name }}</div>
					</div>
				</div>
			</div> -->
		</div>
		<div class="copyright-box">
			<span>{{ globalData.common.footer.copyright }}</span>
			<a class="copyright" target="_blank" href="https://beian.miit.gov.cn/">
				<img src="@/asset/common/images/beian.png" alt="">
				{{ globalData.common.footer.record }}
			</a>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
	data() {
		return {
			name: "",
			phone: "",
			company: "",
		}
	},
	computed: {
		...mapGetters(['globalData']),
		list() {
			return this.globalData?.common?.navs?.find?.(t => t.name === '解决方案')?.route
		},
		footer() {
			return this.globalData.common.footer
		},
		person() {
			return this.footer.contact.person.filter(which => !which.disabled)
		},
		route() {
			return this.$route.path
		}
	},
	methods: {
		submit() {
			const info = {
				name: this.name,
				phone: this.phone,
				company: this.company
			};
			if (!info.name || !info.phone) {
				alert('请填写姓名和手机号');
				return;
			}
			axios
				.post(
					`https://api.dev.zhkcjt.com/support/user/websiteCommit/zhbd-master`,
					{
						name: this.name,
						mobile: this.phone,
						company: this.company,
						plan: "",
						remark: "",
						source: GB_SOURCE || '未知',
						type: 'uav'
					}
				)
				.then((res) => {
					if (res.status == 200) {
						this.name = ""
						this.phone = ""
						this.company = ""
						alert("提交成功")
					}
				});
		},
		goTo(route) {
			const source = route;
			route = String(route)
			if (route === this.route) {
				return false
			} else if (Array.isArray(source)) {
				return this.goTo(source[0].path)
			} else if (route.startsWith('/')) {
				return this.$router.push(route)
			} else if (route.startsWith('http')) {
				window.open(route)
			}
		},
		isSelected(item) {
			if (Array.isArray(item.route)) {
				return item.route.some(each => each.path === this.route)
			} else {
				return item.route === this.route
			}
		}
	},
}
</script>

<style scoped lang="scss">
.footer-submit {
	margin: 40px 0;
	display: flex;
	align-items: center;

	.tj {
		width: 200px;
		height: 56px;
		background: linear-gradient(180deg, #4E99FD 0%, #5482FA 100%);
		border-radius: 2px 2px 2px 2px;
		font-family: Source Han Sans, Source Han Sans;
		font-weight: 500;
		font-size: 24px;
		color: #FFFFFF;
		text-align: center;
		line-height: 56px;
		margin-left: 40px;
	}
}

.footer-input {
	// width: 460px !important;
	height: 54px !important;
	// border-radius: 2px 2px 2px 2px;


	::-webkit-input-placeholder {
		/* Chrome, Opera, Safari */
		font-family: Source Han Sans, Source Han Sans;
		font-weight: 400;
		font-size: 20px;
		color: #999999;
		// padding-left: 28px;

		// padding-top: 50px;
		// line-height: 54px;
	}

	input {
		width: 456px !important;
		height: 54px !important;
		opacity: 0.4;
		border: none;
		border: 1px solid #FFFFFF;
		background: transparent;
		line-height: 54px;
		padding: 0 20px;
		border-radius: 2px 2px 2px 2px;
		outline: none;
		display: block;
		color: #fff;
		font-size: 20px;
	}
}

.footer-container {
	width: 100%;
	padding-top: 40px;
	background-image: url("./assets/footer-bg.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-color: #323537;

	* {
		cursor: default;
	}

	.grid-box {
		margin-top: 30px;
		display: grid;
		gap: 50px;
		grid-template-columns: auto auto;
		justify-content: center;
	}

	.left {
		// display: grid;
		display: flex;
		gap: 40px;
		grid-template-columns: 125px 295px;

		.qrcode-box-item {
			display: flex;
			align-items: center;
			// margin-left: 185px;
		}

		.qrcode-box {
			display: flex;
			flex-direction: column;
			// justify-content: center;
			align-items: center;
			white-space: nowrap;
		}

		.company-info {
			// margin-left: 430px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;

			.company-item {
				margin-bottom: 48px;

				.tips {
					font-family: Source Han Sans, Source Han Sans;
					font-weight: 400;
					font-size: 16px;
					color: #999;
					margin-left: 40px;
					// margin-top: 8px;
				}

				.company-head {
					width: 305px;
					display: flex;
					align-items: center;
					font-family: Source Han Sans, Source Han Sans;
					font-weight: 700;
					font-size: 24px;
					color: #FFFFFF;
				}
			}
		}

		.qr-title {
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: 500;
			font-size: 18px;
			color: #FFFFFF;
			margin-top: 16px;
			text-stroke: 1px rgba(0, 0, 0, 0);
			text-align: left;
			font-style: normal;
			text-transform: none;
			-webkit-text-stroke: 1px rgba(0, 0, 0, 0);
		}

		.qr-explain {
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: 400;
			font-size: 16px;
			color: rgba(255, 255, 255, 0.6);
			margin-top: 16px;
			text-stroke: 1px rgba(0, 0, 0, 0);
			text-align: left;
			font-style: normal;
			text-transform: none;
			-webkit-text-stroke: 1px rgba(0, 0, 0, 0);
		}

		.ci-title {
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: bold;
			font-size: 14px;
			color: white;
		}

		.ci-phone {
			margin-top: 30px;
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: bolder;
			font-size: 24px;
			color: rgba(78, 153, 253, 1);
		}

		.ci-mail {
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: 400;
			font-size: 14px;
			color: rgba(255, 255, 255, 0.6);
			line-height: 24px;
		}
	}

	.right {
		display: grid;
		grid-template-columns: repeat(5, auto);
		gap: 60px;

		.top-title {
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: 500;
			font-size: 20px;
			color: #FFFFFF;
			cursor: pointer;
		}

		.r-children {
			margin-top: 24px;
			display: flex;
			flex-direction: column;
			gap: 16px;

			.r-child {
				font-family: Source Han Sans CN, Source Han Sans CN;
				font-weight: 400;
				font-size: 16px;
				color: rgba(255, 255, 255, 0.6);
				cursor: pointer;
				transition: color .2s;

				&:hover {
					color: rgba(78, 153, 253, 1);
				}
			}
		}
	}

	.copyright-box {
		width: 100%;
		margin-top: 40px;
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 400;
		font-size: 14px;
		color: rgba(255, 255, 255, 0.4);
		border-top: 1px solid rgba(255, 255, 255, 0.4);
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 24px 0;

		img {
			width: 20px;
			/* no */
			height: 22px;
			/* no */
		}
	}

	.copyright {
		margin-left: 30px;
		color: #707070;
		display: inline-flex;
		align-items: center;
		column-gap: 5px;
	}

	.copyright:hover {
		color: gray;
	}
}
</style>
